import { Answers } from '../../contexts/survey'
import { firebase } from './firebaseClient'
import * as Sentry from '@sentry/node'
import { LastPlusPurchase } from '@pages/api/user'
import { Variants } from '@amplitude/experiment-node-server'
import * as jwt from 'jsonwebtoken'

export type IPData = {
    [key: string]: 'string' | 'number' | 'boolean'
}

type IPDataResult = {
    data?: IPData
    error?: ApiError
}

type GetUserResult = {
    user?: User
    subscription?: any
    ipData?: IPData
    amplitudeVariants?: Variants
    error?: ApiError
}

type UpdateUserResult = {
    user?: User
    upgradeToken?: string
    error?: ApiError
}

type User = {
    uid: string
    email: string
}

export type UpdateUser = {
    email?: string
    name?: string
    password?: string
    survey?: {
        answers: Answers
    }
    lastPlusPurchase?: LastPlusPurchase
}

export type ApiError = { type: string; code: string; message: string }

export const getUser = async (): Promise<GetUserResult> => {
    try {
        const idToken = await firebase.auth().currentUser.getIdToken()
        const resp: GetUserResult = await fetch('/api/user', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idToken,
            },
        })
            .then(r => r.text())
            .then(body => {
                try {
                    return JSON.parse(body)
                } catch (e) {
                    return { error: { type: 'api_error', code: 'unknown', message: body } }
                }
            })
        if (resp.error) {
            Sentry.captureException(`API getUser Error: ${JSON.stringify(resp.error)}`)
            return { error: resp.error }
        }
        return {
            user: resp.user,
            subscription: resp.subscription,
            ipData: resp.ipData,
            amplitudeVariants: resp.amplitudeVariants,
        }
    } catch (e) {
        Sentry.captureException(e)
        return {
            error: {
                type: 'api_error',
                code: 'unknown',
                message: e as string,
            },
        }
    }
}

export const updateUser = async (update: UpdateUser): Promise<UpdateUserResult> => {
    try {
        const idToken = await firebase.auth().currentUser.getIdToken()
        const resp: UpdateUserResult = await fetch('/api/user', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idToken,
            },
            body: JSON.stringify({ user: update }),
        })
            .then(r => r.text())
            .then(body => {
                try {
                    return JSON.parse(body)
                } catch (e) {
                    return { error: { type: 'api_error', code: 'unknown', message: body } }
                }
            })
        if (resp.error) {
            Sentry.captureException(`API updateUser Error: ${JSON.stringify(resp.error)}`)
            return { error: resp.error }
        }
        if (resp.upgradeToken) {
            // if a major account change happened (e.g changing email / password)
            // use a upgrade token to prevent logout
            await firebase.auth().signInWithCustomToken(resp.upgradeToken)
        }
        return { user: resp.user }
    } catch (e) {
        Sentry.captureException(e)
        return {
            error: {
                type: 'api_error',
                code: 'unknown',
                message: e as string,
            },
        }
    }
}

export const getUserIP = async (userIP: string): Promise<IPDataResult> => {
    try {
        const resp: { data?: IPData; error?: ApiError } = await fetch(
            `https://ipapi.co/${userIP}/json/?key=Rr1Gp2ax0IYJ46Nw1xQdpL9my5FTJqWkmNQl587mDxYrpuOHZd`,
        )
            .then(r => r.text())
            .then(body => {
                try {
                    return JSON.parse(body)
                } catch (e) {
                    return { error: { type: 'api_error', code: 'unknown', message: body } }
                }
            })
        if (resp.error) {
            Sentry.captureException(`User IP getUserIp Error: ${JSON.stringify(resp.error)}`)
            return { error: resp.error }
        }
        return resp
    } catch (e: unknown) {
        Sentry.captureException(e)
        return {
            error: {
                type: 'api_error',
                code: 'unknown',
                message: e as string,
            },
        }
    }
}

export const getLoginToken = async (): Promise<{ token?: string; error?: ApiError }> => {
    try {
        const idToken = await firebase.auth().currentUser.getIdToken()
        const resp: { loginToken?: string; error?: ApiError } = await fetch('/api/user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idToken,
            },
        })
            .then(r => r.text())
            .then(body => {
                try {
                    return JSON.parse(body)
                } catch (e) {
                    return { error: { type: 'api_error', code: 'unknown', message: body } }
                }
            })
        if (resp.error) {
            Sentry.captureException(`API updateUser Error: ${JSON.stringify(resp.error)}`)
            return { error: resp.error }
        }
        return { token: resp.loginToken }
    } catch (e) {
        Sentry.captureException(e)
        return {
            error: {
                type: 'api_error',
                code: 'unknown',
                message: e as string,
            },
        }
    }
}

export const getJWT = async (): Promise<string | { error?: ApiError }> => {
    try {
        const user = await firebase.auth().currentUser
        return jwt.sign(
            { uid: user.uid, email: user.email },
            'dmcZ79jXew3LWr5a6vsEHfuaHR92fF55jhTmYFVdCvbe7t7pDRckfjc9gsZC3gHE',
        )
    } catch (e) {
        Sentry.captureException(e)
        return {
            error: {
                type: 'api_error',
                code: 'unknown',
                message: e as string,
            },
        }
    }
}
