import React, { useState, createContext, useEffect, useContext } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import * as Sentry from '@sentry/node'
import { getUser, IPData } from '../../services/client/api'
import { PeriodType, Store, SubscriptionStatus } from '../../services/server/revenuecat'
import { Variants } from '@amplitude/experiment-node-server'

export interface User {
    id: string
    email: string
    name: string
    loginViaProvider: boolean
    subscription: {
        status: SubscriptionStatus
        store?: Store
        periodType?: PeriodType
        purchaseDate?: string
        expiresDate?: string
        unsubscribeDetectedAt?: string
        billingIssuesDetectedAt?: string
        trialDetails: { [key: string]: any }
        subscriptionDetails: { [key: string]: any }
        currency: string
        id: string
    }
    ipData: IPData
    amplitudeVariants: Variants
}

export interface Auth {
    initialized: boolean
    user?: User
}

const authContext = createContext<{ auth: Auth }>(null)

export const AuthProvider: React.FC = props => {
    const [auth, setAuth] = useState<Auth>({ initialized: false })

    const onFirebaseAuthStateChange = async (user: firebase.User | null) => {
        if (user) {
            const { uid, email, providerData } = user
            let displayName = user.displayName
            let loginViaProvider = false
            if (providerData && providerData.length > 0) {
                loginViaProvider = providerData.find(data => data.providerId !== 'password') !== undefined
                if (!displayName) {
                    const providerWithName = providerData.find(data => data.displayName && data.displayName.length > 0)
                    if (providerWithName) {
                        displayName = providerWithName.displayName.split(' ')[0]
                    }
                }
            }

            let subscription = auth.user?.subscription
            let ipData = {}
            let amplitudeVariants = {}
            if (user.uid !== auth.user?.id) {
                const data = await getUser()
                subscription = data.subscription
                ipData = data.ipData
                amplitudeVariants = data.amplitudeVariants
            }

            setAuth({
                initialized: true,
                user: {
                    id: uid,
                    name: displayName,
                    email,
                    loginViaProvider,
                    subscription,
                    ipData,
                    amplitudeVariants,
                },
            })
        } else {
            setAuth({ initialized: true })
        }
    }

    useEffect(() => {
        const unsubscribe = firebase.auth().onIdTokenChanged(onFirebaseAuthStateChange)
        return () => unsubscribe()
    }, [])

    useEffect(() => {
        if (auth.user?.id) {
            Sentry.setUser({ id: auth.user.id })
        }
    }, [auth.user?.id])

    return <authContext.Provider value={{ auth }}>{props.children}</authContext.Provider>
}

export const signInAnonymously = async (): Promise<void> => {
    await firebase.auth().signInAnonymously()
}

export const useAuth = (): [User, boolean] => {
    const { auth } = useContext(authContext)
    return [auth.user, auth.initialized]
}

export const useUser = (): User => {
    const { auth } = useContext(authContext)
    return auth.user
}
