import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'

const config = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_CLIENT_API_KEY,
    authDomain: `${process.env.NEXT_PUBLIC_FIREBASE_CLIENT_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${process.env.NEXT_PUBLIC_FIREBASE_CLIENT_PROJECT_ID}.firebaseio.com`,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_CLIENT_PROJECT_ID,
    storageBucket: `${process.env.NEXT_PUBLIC_FIREBASE_CLIENT_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_CLIENT_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_CLIENT_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_CLIENT_MEASUREMENT_ID,
}

const initFirebase = (): void => {
    if (typeof window !== 'undefined' && !firebase.apps.length) {
        firebase.initializeApp(config)
        firebase.analytics.isSupported().then(isSupported => {
            if (isSupported) {
                firebase.analytics()
            }
        })
    }
}

export { initFirebase, firebase }
